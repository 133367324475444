/**
 * @file This file export all the event trackers that can be used in the applications.
 *
 * The concrete implementation can be found in the specific provider files:
 * - {@link `amplitude/events.ts`}
 * - {@link `braze/events.ts`}
 * - {@link `google-tag-manager/events.ts`}
 * - {@link `noodle/events.ts`}
 *
 * If you want to track with a single provider, you need to:
 * 1. Add the event to the corresponding provider file (e.g. `amplitude/events.ts`)
 * 2. Expose the event in this file (e.g. `export { trackXYZ } from './amplitude/events'`)
 *
 * If you want to track the event in multiple providers, you need to:
 * 1. Add the event to the corresponding providers files (e.g. `amplitude/events.ts` and `noodle/events.ts`)
 * 2. Create a new function in this file that calls the event in all the providers (see: `trackClick`)
 */

import { MarketCountryCode } from '@backmarket/http-api'

import {
  setOptIn as setOptInOnAmplitude,
  setUserProperties as setUserPropertiesOnAmplitude,
} from './amplitude'
import * as amplitude from './amplitude/events'
import { setOptIn as setOptInOnBraze } from './braze'
import * as braze from './braze/events'
import * as googleTagManager from './google-tag-manager/events'
import * as noodle from './noodle/events'
import type {
  EventData,
  GDPRData,
  ModalData,
  ResizeData,
  SearchAnalyticsData,
  TrackPageViewParams,
} from './types'

/* Expose single provider events */

export const {
  trackSearchTerm,
  trackBuybackDeviceSpecifications,
  trackBuyBackPlayStationPartnership,
} = braze
export const {
  trackToast,
  trackCarouselImpression,
  trackBuybackNoOffer,
  trackBuybackConfirmation,
  trackSwapModal,
  trackSwap,
} = googleTagManager
export const {
  trackPaymentEvent,
  trackPaymentResultFailureEvent,
  trackPaymentResultSuccessEvent,
} = noodle

export const { trackPartnershipBannerImpression } = amplitude

/* Aggregate events from multiple provider */

export function trackClick(payload: EventData) {
  amplitude.trackClick(payload)
  noodle.trackClick(payload)
  googleTagManager.trackClick(payload)
}

export function trackChange(payload: EventData) {
  amplitude.trackChange(payload)
}

export function trackPageView({ to, from }: TrackPageViewParams) {
  amplitude.trackPageView({ from, to })
  noodle.trackPageView(to)
  googleTagManager.trackPageView(to)
}

export function trackScreenView(payload: EventData) {
  amplitude.trackScreenView(payload)
}

export function trackModal(payload: ModalData) {
  amplitude.trackModal(payload)
  noodle.trackModal(payload)
  googleTagManager.trackModal(payload)
}

export function trackResize(data: ResizeData) {
  setUserPropertiesOnAmplitude({ breakpoint: data.breakpointValue })
  googleTagManager.trackResize(data)
  noodle.trackResize(data)
}

export function trackGDPR(data: GDPRData, country: MarketCountryCode) {
  setOptInOnAmplitude(country === MarketCountryCode.IT ? false : data.analytics)
  setOptInOnBraze(data.userExperience && data.advertising)
  googleTagManager.trackGDPR(data)
}

export function trackFormSubmit(payload: EventData) {
  amplitude.trackFormSubmit(payload)
  googleTagManager.trackFormSubmit(payload)
}

export function trackSearchAnalytics(data: SearchAnalyticsData) {
  noodle.trackSearchAnalytics(data)
}

export function trackHoverOver(payload: EventData) {
  amplitude.trackHoverOver(payload)
}

export function trackVideosBlockImpression(payload: EventData) {
  amplitude.trackVideosBlockImpression(payload)
}

export function trackVideoImpression(payload: EventData) {
  amplitude.trackVideoImpression(payload)
}

export function trackReviewImpression(payload: EventData) {
  amplitude.trackReviewImpression(payload)
}

export function trackReviewBlockImpression(payload: EventData) {
  amplitude.trackReviewBlockImpression(payload)
}

export function trackProductImpressionBatched(payload: EventData[]) {
  amplitude.trackProductImpressionBatched(payload)
  googleTagManager.trackProductImpressionBatched(payload)
}

export function trackProductImpression(payload: EventData) {
  amplitude.trackProductImpression(payload)
  googleTagManager.trackProductImpression(payload)
}

export function trackProductClick(payload: EventData) {
  amplitude.trackProductClick(payload)
  googleTagManager.trackProductClick(payload)
}

export function trackErrors(payload: EventData) {
  amplitude.trackErrors(payload)
}

export function trackLandingPage(payload: EventData) {
  googleTagManager.trackLandingPage(payload)
}

export function trackLandingBannerImpression(payload: EventData) {
  amplitude.trackLandingBannerImpression(payload)
  googleTagManager.trackLandingBannerImpression(payload)
}

export function trackLandingBannerClick(payload: EventData) {
  amplitude.trackLandingBannerClick(payload)
  googleTagManager.trackLandingBannerClick(payload)
}

export function trackRecyclingProduct(payload: EventData) {
  amplitude.trackRecyclingProduct(payload)
}

export function trackRecyclingOrder(payload: EventData) {
  amplitude.trackRecyclingOrder(payload)
}

export function trackAddToCart(payload: EventData) {
  amplitude.trackAddToCart(payload)
  googleTagManager.trackAddToCart(payload)
  braze.trackAddToCart(payload)
}

export function trackAddToCartBatch({
  products,
  list,
}: {
  products: EventData[]
  list: string
}) {
  amplitude.trackAddToCartBatch({ products })
  googleTagManager.trackAddToCartBatch({ products, list })
}

export function trackCarouselClick(payload: EventData) {
  amplitude.trackCarouselClick(payload)
  googleTagManager.trackCarouselClick(payload)
}

export function trackPaginationClicked(payload: EventData) {
  amplitude.trackPaginationClicked(payload)
}

export function trackProductPage(payload: EventData) {
  amplitude.trackProduct(payload.product as EventData)
  googleTagManager.trackProduct(payload)
  braze.trackProduct(payload.product as EventData)
}

export function trackProductBlockImpression(payload: EventData) {
  amplitude.trackProductBlockImpression(payload)
}

export function trackToastView(payload: EventData) {
  amplitude.trackToastView(payload)
}

export function trackEcoBlockImpression(payload: EventData) {
  amplitude.trackEcoBlockImpression(payload)
}

export function trackAddToMyFavorites(payload: EventData) {
  amplitude.trackAddToMyFavorites(payload)
  braze.trackAddToMyFavorites(payload)
}

export function trackFunnel({
  pageType,
  products,
  step,
  swap,
}: {
  pageType: string
  products: EventData[]
  step: number
  swap: boolean
}) {
  amplitude.trackFunnel({ products, pageType, swap })
  googleTagManager.trackFunnel({ products, step, swap })
}

export function trackPaymentResultSuccess({
  payment,
  products,
}: {
  payment: EventData
  products: EventData[]
}) {
  amplitude.trackPaymentResultSuccess({ payment, products })
  googleTagManager.trackPaymentResultSuccess({ payment, products })
}

export function trackPaymentResultFailure({
  paymentMethod,
  errorCode,
  errorReason,
}: EventData) {
  amplitude.trackPaymentResultFailure({ paymentMethod, errorCode, errorReason })
  googleTagManager.trackPaymentResultFailure({
    paymentMethod,
    errorCode,
    errorReason,
  })
}

export function trackPostPurchasePayment(payload: EventData) {
  amplitude.trackPostPurchasePayment(payload)
}

export function trackPromptNotificationImpression(payload: EventData) {
  googleTagManager.trackPromptNotificationImpression(payload)
  amplitude.trackPromptNotificationImpression(payload)
}

export function trackRemoveFromCart(payload: EventData) {
  googleTagManager.trackRemoveFromCart(payload)
}

export function trackCheckpointsImpression(payload: EventData) {
  amplitude.trackCheckpointsImpression(payload)
}

export function trackProduct360Impression(payload: EventData) {
  amplitude.trackProduct360Impression(payload)
}

export function trackResetPassword() {
  googleTagManager.trackResetPassword()
}
