import { pickBy } from './pickBy.js'

const isArrayEmpty = (array: unknown) => Array.isArray(array) && !array.length

/**
 * Remove empty values and empty arrays in object
 */
export function removeEmptyValuesInObject(object: Record<string, unknown>) {
  return pickBy(object, (value: unknown) => {
    return (
      value !== null &&
      value !== undefined &&
      value !== '' &&
      !isArrayEmpty(value)
    )
  })
}
