<template>
  <RevContainer class="py-6">
    <h1 class="heading-1 my-32">Search & Recommendation Playground</h1>
    <p class="italic">
      This page is enable only on the Staging and Preprod Environments.
    </p>
    <ul>
      <li v-for="item in items" :key="item.url">
        <RevLink :to="item.url">{{ item.title }}</RevLink>
      </li>
    </ul>
  </RevContainer>
</template>

<script lang="ts" setup>
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { RevContainer } from '@ds/components/Container'
import { RevLink } from '@ds/components/Link'

import { useNoIndexMeta } from '~/composables/useNoIndexMeta'

useNoIndexMeta()

const locale = useI18nLocale()

const items = [
  {
    url: `/${locale}/sr-demopage/product-cards`,
    title: 'Product Cards Demo',
  },
  {
    url: `/${locale}/sr-demopage/active-filters`,
    title: 'Active Filters Demo',
  },
  {
    url: `/${locale}/sr-demopage/pagination`,
    title: 'Pagination Demo',
  },
  {
    url: `/${locale}/sr-demopage/refinements`,
    title: 'Refinements Demo',
  },
  {
    url: `/${locale}/sr-demopage/trust-list`,
    title: 'Trust List Demo',
  },
  {
    url: `/${locale}/sr-demopage/action-sticky-bar`,
    title: 'Action Sticky Bar Demo',
  },
]
</script>
