/**
 * Breakpoints are numbers from the smallest width '0' to the largest width '3'.
 * Thus, it's possible to compare breakpoints.
 *
 * @example
 * if(getCurrentBreakpoint() >= Breakpoint.SM) {
 *   // SM, MD or LG
 * }
 */
export enum Breakpoint {
  XS = 0,
  SM = 1,
  MD = 2,
  LG = 3,
}

/**
 * @ignore Use `getCurrentBreakpoint`or `useBreakpoint` instead.
 * This is exported for legacy reasons. It is only needed for tracking purposes.
 */
export const BreakpointQueries: Record<Breakpoint, string> = {
  [Breakpoint.LG]: '(min-width: 1200px)',
  [Breakpoint.MD]: '(min-width: 768px)',
  [Breakpoint.SM]: '(min-width: 375px)',
  [Breakpoint.XS]: '(min-width: 0px)',
}

/**
 * Based on the current window size, returns the breakpoint.
 */
export const getCurrentBreakpoint = () => {
  const matchMedia = (media: string) => window.matchMedia(media).matches

  const breakpoints = [
    Breakpoint.LG,
    Breakpoint.MD,
    Breakpoint.SM,
    Breakpoint.XS,
  ]

  // Because of the max & min width, we have to start from the larger breakpoint to the smaller one.
  return (
    breakpoints.find((breakpoint) =>
      matchMedia(BreakpointQueries[breakpoint]),
    ) ?? Breakpoint.LG
  )
}
