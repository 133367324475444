<template>
  <div v-if="hasLinks">
    <BlockTitle :subtitle="subtitle" :title="title" />

    <RevButtonTiny
      v-for="cta in links"
      :key="`${cta.label}_${id}`"
      class="mb-12 mr-12"
      :rel="cta.link?.rel"
      :target="cta.link?.target"
      :to="cta.link?.href"
      :tracking="{ ...tracking, name: cta.label }"
      variant="primary"
    >
      {{ cta.label }}
    </RevButtonTiny>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { RelatedLinksContent } from '@backmarket/http-api/src/api-specs-content'
import BlockTitle from '@backmarket/nuxt-layer-cms/BlockTitle.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { RevButtonTiny } from '@ds/components/ButtonTiny'

export type RelatedLinksProps = ContentBlockProps & RelatedLinksContent

const props = withDefaults(defineProps<RelatedLinksProps>(), {
  title: '',
  subtitle: '',
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const hasLinks = computed(() => props.links?.length > 0)
</script>
