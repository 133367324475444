<template>
  <main class="bg-static-info-low">
    <RevContainer>
      <Navigation class="mt-8" data-test="breadcrumb" />

      <div class="grid-standard pb-72 md:pt-56">
        <div class="max-lg:col-span-3 lg:col-start-2 lg:col-end-8">
          <div
            class="mb-7 flex flex-col md:flex-row md:items-end md:justify-between"
          >
            <div>
              <span class="text-static-default-low body-1 mb-4">
                {{ i18n(translations.subTitle) }}
              </span>

              <h2 class="heading-1">{{ i18n(translations.title) }}</h2>
            </div>
          </div>

          <RevIllustration
            class="mx-auto my-12 p-32 md:hidden"
            :height="230"
            src="/img/common/BuybackZealot.svg"
            :width="230"
          />

          <p class="text-static-default-mid body-1 mb-24">
            {{ i18n(translations.description) }}
          </p>

          <RevButton
            class="mb-12"
            full-width="responsive"
            :to="resolveLocalizedRoute({ name: DASHBOARD.MY_ORDERS.SELF })"
            variant="primary"
          >
            {{ i18n(translations.goToMyOrdersCta) }}
          </RevButton>

          <p class="text-static-default-low body-2">
            {{ i18n(translations.tip) }}
          </p>
        </div>

        <RevIllustration
          class="self-center justify-self-center max-lg:col-span-1 max-md:hidden lg:col-start-9 lg:col-end-12"
          :height="251"
          src="/img/common/BuybackZealot.svg"
          :width="251"
        />
      </div>
    </RevContainer>
  </main>
</template>

<script setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { DASHBOARD } from '~/scopes/dashboard/routes'

import translations from './StraightRefundConfirmationPage.translations'
import Navigation from './components/Navigation.vue'

const resolveLocalizedRoute = useRouteLocationWithLocale()

const i18n = useI18n()
</script>
