import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'
import { ROLES } from '@backmarket/nuxt-layer-buyback/components/OrderConversation/OrderConversation.constants'

import { ROUTES as AUTH_ROUTES } from '~/scopes/auth/route-names'
import { CMS } from '~/scopes/cms/routes-names'

import type { Features } from '../../features'

import { ROUTE_NAMES } from './constants'

export default ({
  features,
  market,
}: {
  features: Features
  market: Market
}): RouteRecordRaw[] => {
  const { FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_CONVERSATION } =
    useRuntimeConfig().public
  const disableClientSideNavigation = !(
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_CONVERSATION as string
  )?.includes(market.countryCode)

  const isBuybackEnabled = features.buyback?.enabled

  if (isBuybackEnabled) {
    return [
      {
        name: ROUTE_NAMES.DEVICE_ASSESSMENT,
        path: 'buyback-funnel/device/:slug/:id',
        component: () => import('./pages/DeviceAssessment.vue'),
      },
      {
        name: ROUTE_NAMES.OFFER,
        path: 'buyback-funnel/device/:slug/:id/offer/:listingId',
        component: () => import('./pages/Offer.vue'),
      },
      {
        name: ROUTE_NAMES.NO_OFFER,
        path: 'buyback-funnel/device/:slug/:id/no-offer',
        component: () => import('./pages/NoOffer.vue'),
      },
      {
        name: ROUTE_NAMES.BANK,
        path: 'buyback-funnel/device/:slug/:id/offer/:listingId/bank',
        component: () => import('./pages/Bank.vue'),
        meta: {
          auth: {
            required: true,
            redirection: {
              name: AUTH_ROUTES.AUTH.LOGIN,
              query: { bm_journey: CMS.BUYBACK },
            },
          },
        },
      },
      {
        name: ROUTE_NAMES.ADDRESS,
        path: 'buyback-funnel/device/:slug/:id/offer/:listingId/address',
        component: () => import('./pages/Address.vue'),
        meta: {
          auth: {
            required: true,
            redirection: {
              name: AUTH_ROUTES.AUTH.LOGIN,
              query: { bm_journey: CMS.BUYBACK },
            },
          },
        },
      },
      {
        name: ROUTE_NAMES.SHIPPING,
        path: 'buyback-funnel/device/:slug/:id/offer/:listingId/address/:addressId/shipping',
        component: () => import('./pages/Shipping.vue'),
        meta: {
          auth: {
            required: true,
            redirection: {
              name: AUTH_ROUTES.AUTH.LOGIN,
              query: { bm_journey: CMS.BUYBACK },
            },
          },
        },
      },
      {
        name: ROUTE_NAMES.CONFIRMATION,
        path: 'buyback-funnel/confirmation/:id',
        component: () => import('./pages/Confirmation.vue'),
        meta: {
          auth: {
            required: true,
            redirection: {
              name: AUTH_ROUTES.AUTH.LOGIN,
            },
          },
        },
      },
      {
        name: ROUTE_NAMES.CONVERSATION,
        path: 'dashboard/sales/:id/conversation',
        component: () =>
          import(
            '@backmarket/nuxt-layer-buyback/components/OrderConversation/OrderConversation.vue'
          ),
        meta: {
          auth: {
            required: true,
            redirection: {
              name: AUTH_ROUTES.AUTH.LOGIN,
            },
          },
          layout: 'minimal',
          disableClientSideNavigation: () => disableClientSideNavigation,
        },
        props: {
          role: ROLES.CUSTOMER,
        },
      },
    ]
  }

  return []
}
