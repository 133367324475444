<template>
  <FunnelBreadcrumb />
  <RevContainer class="mx-auto mb-56 w-full md:w-[41.25rem]">
    <RevIllustration
      :alt="i18n(translations.title)"
      class="m-auto my-48 block"
      :height="160"
      src="/img/buyback/no-offer.svg"
      :width="160"
    />
    <header>
      <h1 class="heading-1 mb-16">
        {{ i18n(translations.title) }}
      </h1>
    </header>

    <p class="body-1 mb-32">
      {{
        i18n(
          translations[showRecyclingBlock ? 'recyclingParagraph' : 'paragraph'],
        )
      }}
    </p>

    <RevButton
      v-if="!showRecyclingBlock"
      data-test="buyback-btn"
      :to="buybackHomeRoute"
      variant="secondary"
    >
      {{ i18n(translations.button) }}
    </RevButton>

    <div v-if="showRecyclingBlock">
      <RevInfoBlock
        class="body-2"
        data-test="recycling-info-block"
        :icon="IconLeaf"
        :title="i18n(translations.infoBlockTitle)"
        variant="success"
      >
        <div>
          {{ i18n(translations.infoBlockParagraph) }}
        </div>
      </RevInfoBlock>

      <RevButton
        class="my-32"
        data-test="recycle-btn"
        full-width="always"
        :to="getRecyclingRoute()"
        variant="primary"
        @click="trackRecyclingNavigation"
      >
        {{ i18n(translations.cta) }}
      </RevButton>

      <h3 class="heading-3">
        {{ i18n(translations.partnerTitle) }}
      </h3>

      <RecyclingPartnerBlock class="mt-16" />
    </div>
  </RevContainer>
</template>

<script lang="ts" setup>
import { useRoute, useRuntimeConfig } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconLeaf } from '@ds/icons/IconLeaf'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { CMS } from '~/scopes/cms/routes-names'
import { ROUTES } from '~/scopes/recycling/routes-names'

import FunnelBreadcrumb from '../components/FunnelBreadcrumb/FunnelBreadcrumb.vue'
import RecyclingPartnerBlock from '../components/RecyclingPartnerBlock/RecyclingPartnerBlock.vue'

import translations from './NoOffer.translations'

const i18n = useI18n()
const country = useMarketplace().market.countryCode
const resolveLocalizedRoute = useRouteLocationWithLocale()
const route = useRoute()
const { trackClick } = useTracking()
const {
  FF_RECYCLING_FUNNEL_COUNTRIES,
  FF_RECYCLING_HOME_COUNTRIES,
  KILL_BUYBACK_RECYCLING,
} = useRuntimeConfig().public

const hasRecyclingFunnel = FF_RECYCLING_FUNNEL_COUNTRIES.includes(country)

const hasLegacyRecyclingLink =
  FF_RECYCLING_HOME_COUNTRIES.includes(country) && !hasRecyclingFunnel

const isProductSmartphone = route.params?.id === 'smartphone'
const showRecyclingBlock =
  !KILL_BUYBACK_RECYCLING &&
  (hasLegacyRecyclingLink || hasRecyclingFunnel) &&
  isProductSmartphone

const buybackHomeRoute = resolveLocalizedRoute({
  name: CMS.BUYBACK,
  params: {
    pageName: 'home',
  },
})

function getRecyclingRoute() {
  if (hasLegacyRecyclingLink) {
    return resolveLocalizedRoute({
      name: CMS.EVENT,
      params: {
        pageName: 'recycling',
      },
    })
  }

  return resolveLocalizedRoute({
    name: ROUTES.OFFER,
    params: {
      deviceCategory: 'smartphone',
    },
  })
}

function trackRecyclingNavigation() {
  trackClick({
    name: 'go_to_recycling_cta',
    page_type: 'no_offer_resale',
    zone: 'recycling',
  })
}
</script>
