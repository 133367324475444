import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'

import type { EventData } from '../../types'
import { formatPrice } from '../../utils/formatPrice'

export function favoritesModel({
  brand,
  category,
  color,
  currency,
  hasNewBattery,
  isDisabled = false,
  mobileDeeplink,
  model,
  name,
  position,
  price,
  productList = 'my_favorites',
  uuid,
  webUrl,
}: EventData) {
  return removeEmptyValuesInObject({
    product_available: !isDisabled,
    product_brand: brand,
    product_category: category,
    product_color: color,
    product_hasNewBattery: hasNewBattery,
    product_uuid: uuid,
    product_list: productList,
    product_mobile_deeplink: mobileDeeplink,
    product_model: model,
    product_name: name,
    product_position: position,
    product_price: formatPrice({ amount: price, currency }),
    product_web_url: webUrl,
  })
}

export const favoritesModelCollection = (
  favoriteProducts: EventData[] = [],
) => {
  const favoriteProductsList = Array.isArray(favoriteProducts)
    ? favoriteProducts
    : [favoriteProducts]

  return favoriteProductsList.map((favoriteProduct, index) => ({
    ...favoritesModel({
      ...favoriteProduct,
      position: favoriteProduct.position || index + 1,
    }),
  }))
}
