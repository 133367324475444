const koreanRegexp = /[\uff9f\u3131-\uD79D]/

// see cConfluence page https://backmarket.atlassian.net/wiki/spaces/FRONT/pages/2889613669/Japanese+subsetting
const japaneseRegexp =
  /[\u3040-\u309f\u30a0-\u30ff\u4e00-\u9fff\u3400-\u4dbf\uf900-\ufaff]/

/**
 * Test if a string is a korean character
 * see code references {@link https://www.loc.gov/marc/specifications/specchareacc/KoreanHangul.html}
 *
 * @param {String} character e.g 라
 * @return {boolean}
 */
export function isKoreanCharacter(character: string): boolean {
  return koreanRegexp.test(character)
}

/**
 * Test if a string is a japanese character
 * see code references
 * {@link https://www.loc.gov/marc/specifications/specchareacc/JapaneseHiraganaKatakana.html}
 * and
 * {@link https://www.loc.gov/marc/specifications/specchareacc/EastAsianIdeographs.html}
 *
 * @param {String} character e.g 恵
 * @return {boolean}
 */
export function isJapaneseCharacter(character: string): boolean {
  return japaneseRegexp.test(character)
}
