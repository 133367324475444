<template>
  <RevContainer class="py-6">
    <h1 class="heading-1 my-32">Pagination component</h1>
    <p>Page # is {{ currentPage }} of {{ pageCount }}</p>

    <Pagination
      v-model="pageInput"
      class="mb-56 mt-20"
      data-test="pagination"
      :page-count="pageCount"
    />
  </RevContainer>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import { RevContainer } from '@ds/components/Container'

import { useNoIndexMeta } from '~/composables/useNoIndexMeta'
import Pagination from '~/scopes/product-list/components/Pagination.vue'

useNoIndexMeta()
const currentPage = ref(1)
const pageCount = ref(42)

const pageInput = ref(0)
</script>
