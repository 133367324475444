import { reactive } from 'vue'

import { authLegacyAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpStore } from '@backmarket/nuxt-module-http/useHttpStore'
import { defineStore } from 'pinia'

import { useAuthStore } from './useAuthStore'

type State = {
  firstName?: string
  lastName?: string
  email?: string
  emailHashed?: string
  isShadowingClient: boolean
  isShadowingMerchant: boolean
  isStaff: boolean
  clientId?: number
  merchantId?: number
  cartItemsCount: number
  lastOrderDate?: Date
  numberOfOrders: number
  phone?: {
    dial: string
    phone: string
  }
  lifetimeRevenue: {
    amount: number
    currency?: string
  }
}

type PrefetchUserPromise = Promise<authLegacyAPI.GetUserAuth.Response>

const defaultState: State = {
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  emailHashed: undefined,
  isShadowingClient: false,
  isShadowingMerchant: false,
  isStaff: false,
  clientId: undefined,
  merchantId: undefined,
  cartItemsCount: 0,
  lastOrderDate: undefined,
  numberOfOrders: 0,
  phone: undefined,
  lifetimeRevenue: {
    amount: 0,
    currency: undefined,
  },
}

/**
 * Pinia store exposing the current authenticated user.
 *
 * For details about the actions, please refer to their own documentation.
 */
export const useUserStore = defineStore('user', () => {
  const authStore = useAuthStore()

  const state = reactive({ ...defaultState })

  /**
   * Prefetch user without filling the related auth store.
   * This is useful to start fetching without interfering with the hydration process.
   */
  async function prefetchUser(): PrefetchUserPromise {
    return await $httpFetch(authLegacyAPI.getUserAuth)
  }

  /**
   * Fetch the currently authenticated user information.
   *
   * In order to retrieve all the user information from the Badoom endpoint, a
   * proper `sessionid` cookie must be passed alongside the request. If you are
   * already using the `useLogin` composable to handle the authentication flow,
   * this is transparent for you, and all you have to do is call this action.
   *
   * Note that the `fetchUser` action also takes care of filling the related
   * auth store (retrieved via `useAuthStore`).
   *
   * @example
   * const userStore = useUserStore()
   *
   * userStore.firstName // undefined
   * userStore.lastName // undefined
   *
   * await userStore.fetchUser()
   *
   * userStore.firstName // string
   * userStore.lastName // string
   */

  async function fetchUser(prefetchPromise?: PrefetchUserPromise) {
    const user = prefetchPromise ? await prefetchPromise : await prefetchUser()

    state.firstName = user.firstName
    state.lastName = user.lastName
    state.email = user.username
    state.emailHashed = user.emailHashed
    state.isShadowingClient = user.isStaffShadowingClient
    state.isShadowingMerchant = user.isStaffShadowingMerchant
    state.isStaff = user.isStaff
    state.clientId = user.clientId
    state.merchantId = user.merchantId
    state.cartItemsCount = user.itemsInCartCount || 0
    state.numberOfOrders = user.numberOfOrders || 0

    if (user.countryDialInCode && user.phone) {
      state.phone = {
        dial: user.countryDialInCode,
        phone: user.phone,
      }
    }

    if (user.lifetimeRevenue) {
      state.lifetimeRevenue = user.lifetimeRevenue
    }

    if (user.lastOrderDate) {
      state.lastOrderDate = new Date(user.lastOrderDate)
    }

    authStore.userId = user.id
    authStore.encryptedUserId = user.encryptedUserId

    const httpStore = useHttpStore()
    httpStore.csrf = user.csrf
  }

  function $reset() {
    Object.assign(state, defaultState)
  }

  /**
   * Set email of the user
   */
  function setEmail(email: string) {
    Object.assign(state, { email })
  }

  return {
    user: state,
    fetchUser,
    prefetchUser,
    setEmail,
    $reset,
  }
})
