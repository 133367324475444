<template>
  <RevBreadcrumb :ariaLabel="i18n(translations.breadcrumb)" :breads="breads" />
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'
import { computed } from 'vue'
import type { RouteParams } from 'vue-router'

import { MarketCountryCode } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { CMS } from '~/scopes/cms/routes-names'
import { HOME } from '~/scopes/home/route-names'

import { ROUTE_NAMES } from '../../constants'

import translations from './FunnelBreadcrumb.translations'

interface Location {
  name: string
  type: string
  params?: RouteParams
}

interface Bread {
  link: Location
  title: string
}

const route = useRoute()
const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const breads = computed(() => {
  const country: MarketCountryCode = useMarketplace().market.countryCode
  const hasBankStep = [
    MarketCountryCode.FR,
    MarketCountryCode.DE,
    MarketCountryCode.GB,
    MarketCountryCode.ES,
  ].includes(country)

  const routeList = [
    {
      title: i18n(translations.breadcrumbHome),
      link: resolveLocalizedRoute({
        name: HOME,
      }),
    },
    {
      title: i18n(translations.breadcrumbCategories),
      link: resolveLocalizedRoute({
        type: 'internal',
        name: CMS.BUYBACK,
        params: {
          pageName: 'home',
        },
      }),
    },
    {
      title: i18n(translations.breadcrumbDevice),
      link: {
        type: 'internal',
        name: ROUTE_NAMES.DEVICE_ASSESSMENT,
        params: {
          locale: toBcp47Locale(i18n.currentLocale),
          slug: route?.params?.slug,
          id: route?.params?.id,
        },
      },
    },
    {
      link: resolveLocalizedRoute({
        type: 'internal',
        name:
          route.name === ROUTE_NAMES.NO_OFFER
            ? ROUTE_NAMES.NO_OFFER
            : ROUTE_NAMES.OFFER,
        params: route?.params,
      }),
      title: i18n(translations.breadcrumbOffer),
    },
    hasBankStep
      ? {
          title: i18n(translations.breadcrumbBank),
          link: resolveLocalizedRoute({
            type: 'internal',
            name: ROUTE_NAMES.BANK,
            params: route?.params,
          }),
        }
      : null,
    {
      title: i18n(translations.breadcrumbAddress),
      link: resolveLocalizedRoute({
        type: 'internal',
        name: ROUTE_NAMES.ADDRESS,
        params: route?.params,
      }),
    },
    {
      title: i18n(translations.breadcrumbShipping),
      link: resolveLocalizedRoute({
        type: 'internal',
        name: ROUTE_NAMES.SHIPPING,
        params: route?.params,
      }),
    },
  ]

  // Removing eventual nulls from routeList
  const breadsArray = routeList.filter((bread) => bread) as Array<Bread>

  const activeRouteIndex = breadsArray.findIndex(
    ({ link }) => link?.name === route?.name,
  )

  return breadsArray.slice(0, activeRouteIndex + 1)
})
</script>
