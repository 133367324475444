import { type Ref, computed } from 'vue'

import { useBreakpoint } from '@backmarket/utils/composables/useBreakpoint'
import { tw } from '@backmarket/utils/string/tw'
import { IconAvatar } from '@ds/icons/IconAvatar'

import {
  isJapaneseCharacter,
  isKoreanCharacter,
} from '../utils/country/country'

export const AVATAR_COLORS = [
  tw`bg-static-default-mid`,
  tw`bg-static-warning-mid`,
  tw`bg-static-danger-mid`,
  tw`bg-static-success-mid`,
  tw`bg-static-info-mid`,
]

export function useAvatar(name: Ref<string | undefined>) {
  const breakpoint = useBreakpoint()

  const firstName = computed(() => name?.value || '')

  const size = computed(() => (breakpoint.value > 1 ? 'medium' : 'small'))

  const hasJapaneseOrKoreanFirstName = computed(
    () =>
      isJapaneseCharacter(firstName.value) ||
      isKoreanCharacter(firstName.value),
  )

  const label = computed(() => {
    if (hasJapaneseOrKoreanFirstName.value) return firstName.value

    return firstName.value.charAt(0)
  })

  const thumbnail = computed(() =>
    !firstName.value || hasJapaneseOrKoreanFirstName.value
      ? IconAvatar
      : undefined,
  )

  const backgroundColor =
    AVATAR_COLORS[firstName.value.charCodeAt(0) % AVATAR_COLORS.length]

  return {
    label,
    size,
    thumbnail,
    backgroundColor,
  }
}
