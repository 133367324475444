export type ExperimentName = `experiment.${string}`
type Experiment = {
  defaultValue: 'noVariation'
  urlPatterns: Array<string>
}

/**
 * This dictionary holds the configuration for all the experiments currently
 * being assigned by the experiments worker.
 *
 * @property defaultValue - Must be set to noVariation for all of them.
 * @property urlPatterns - Allows you to scope each experiment to their
 * associated set of pages. Those patterns are used on the worker side
 * to know if the experiment will be used to process the current request
 * or not. It helps us improving our cache hit ratio, and thus the
 * overall server-side performance.
 */
export const config = {
  'experiment.globalAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.homeAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/?$' /* Home page */],
  },
  'experiment.landingAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/l/.*' /* Landing page */],
  },
  'experiment.productAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.checkoutAA': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.homeCMSPageName': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/?$' /* Home page - permanent AB test */],
  },
  'experiment.ppMixAndMatch': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppSplitPriceEuropeVersion': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppSplitPriceUSVersion': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.serviceFeePriceGroup24': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.homeBuybackPageName': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/buyback/.*' /* All Buyback Pages */],
  },
  'experiment.ppBuybackDropdown': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppBouyguesPosition': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppVisibleByVerizonPartnership': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppGoodDealStaffPick': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppBouyguesOffersFilter': {
    defaultValue: 'noVariation',
    /** Variations:
     * withoutB&YOU1999Offer
     * withoutB&YOU1499Offer
     * noFilter
     *  */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.insuranceCartRevamp': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.insuranceCartRevampV2': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.insuranceMonthlySubscription': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.insuranceBenefitList': {
    defaultValue: 'noVariation',
    /** Variations:
     * insuranceBenefitFullList
     * insuranceBenefitShortList */
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/f2/.*' /* Checkout pages */],
  },
  'experiment.fakeBytelMobilePlan': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.killOneyFr': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages */],
  },
  'experiment.enableClearpayUK': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages (in the footer) */],
  },
  'experiment.enableKlarnaIT': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}.*' /* All pages (in the footer) */],
  },
  'experiment.ppPremiumGrade': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
  'experiment.ppImmersiveStories': {
    defaultValue: 'noVariation',
    urlPatterns: ['^/[a-z]{2}-[a-z]{2}/p/.*' /* Product page */],
  },
} satisfies Record<ExperimentName, Experiment>
