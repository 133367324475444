export const filters = {
  page: '1',
  price: ['0', '1000'],
}

export const filteredFacets = [
  {
    name: 'condition',
    title: 'Condition',
    type: 'checkbox',
    isSortedByBusiness: true,
    facetName: 'condition',
    facetValues: [
      {
        label: 'New',
        facet_value: '10 New',
        count: 100,
      },
      {
        label: 'Used - Like New',
        facet_value: '20 Used - Like New',
        count: 50,
      },
      {
        label: 'Used - Good',
        facet_value: '30 Used - Good',
        count: 75,
      },
      {
        label: 'Used - Acceptable',
        facet_value: '40 Used - Acceptable',
        count: 25,
      },
      {
        label: 'For parts or not working',
        facet_value: '50 For parts or not working',
        count: 5,
      },
    ],
  },
  {
    name: 'model',
    title: 'Modèle',
    type: 'checkbox',
    isSortedByBusiness: true,
    facetName: 'model',
    facetValues: [
      {
        label:
          'iPhone 12 Pro Max Extremely Long Model Name for Testing UI Overflow',
        facet_value:
          '001 iPhone 12 Pro Max Extremely Long Model Name for Testing UI Overflow',
        count: 3,
      },
      {
        label: 'iPhone 11',
        facet_value: '002 iPhone 11',
        count: 1,
      },
      {
        label: 'iPhone XR',
        facet_value: '003 iPhone XR',
        count: 1,
      },
      {
        label: 'iPhone X',
        facet_value: '018 iPhone X',
        count: 3,
      },
      {
        label: 'iPhone 8',
        facet_value: '999 iPhone 8',
        count: 6,
      },
      {
        label: 'iPhone 7',
        facet_value: '020 iPhone 7',
        count: 1,
      },
      {
        label: 'Galaxy S8',
        facet_value: '047 Galaxy S8',
        count: 1,
      },
    ],
  },
  {
    name: 'storage',
    title: 'Capacité de stockage (Go)',
    type: 'checkbox',
    isSortedByBusiness: true,
    facetName: 'storage',
    facetValues: [
      {
        label: '16 Go',
        facet_value: '16000 16 Go',
        count: 32,
      },
      {
        label: '64 Go',
        facet_value: '64000 64 Go',
        count: 1560,
      },
      {
        label: '128 Go',
        facet_value: '128000 128 Go',
        count: 26,
      },
      {
        label: '256 Go',
        facet_value: '256000 256 Go',
        count: 2,
      },
      {
        label: '512 Go',
        facet_value: '512000 512 Go',
        count: 1,
      },
      {
        label: '1 TB',
        facet_value: '1000000 1 TB',
        count: 1,
      },
    ],
  },
  {
    name: 'shipping_free',
    title: 'Livraison offerte',
    type: 'radio',
    facetName: 'shipping_free',
    facetValues: [
      {
        label: 'Oui',
        facet_value: 'Oui',
        count: 1648,
      },
      {
        label: 'Non',
        facet_value: 'Non',
        count: 1649,
      },
    ],
  },
]

export const facetsFilterProps = {
  facet: {
    name: 'model',
    title: 'Modèle',
    type: 'checkbox',
    isSortedByBusiness: true,
    facetName: 'model',
    facetValues: [
      {
        label:
          'iPhone 12 Pro Max Extremely Long Model Name for Testing UI Overflow',
        facet_value:
          '001 iPhone 12 Pro Max Extremely Long Model Name for Testing UI Overflow',
        count: 3,
      },
      {
        label: 'iPhone 11',
        facet_value: '002 iPhone 11',
        count: 1,
      },
      {
        label: 'iPhone XR',
        facet_value: '003 iPhone XR',
        count: 1,
      },
      {
        label: 'iPhone X',
        facet_value: '018 iPhone X',
        count: 3,
      },
      {
        label: 'iPhone 8',
        facet_value: '999 iPhone 8',
        count: 6,
      },
      {
        label: 'iPhone 7',
        facet_value: '020 iPhone 7',
        count: 1,
      },
      {
        label: 'Galaxy S8',
        facet_value: '047 Galaxy S8',
        count: 1,
      },
    ],
  },
  filteredFacets: [],
  disabled: false,
}
