import { defineNuxtPlugin } from '#imports'

import { isTestEnv } from '@backmarket/utils/env/isTestEnv'

import { NUXT_STATE_KEY, PROCESS_KEY } from './constants'
import type { I18n } from './types'
import { getRequestLocale } from './utils/getRequestLocale'

export type Injections = {
  i18n: I18n
}

export default defineNuxtPlugin<Injections>((nuxt) => {
  if (nuxt.ssrContext) {
    const locale = getRequestLocale(nuxt.ssrContext.event)

    if (!process[PROCESS_KEY]) {
      throw new Error(
        '[nuxt-module-i18n] Cannot retrieve translations from the main process',
      )
    }

    const translations = process[PROCESS_KEY].translationsByLocale[locale]

    if (translations === undefined) {
      throw new Error(
        '[nuxt-module-i18n] Cannot retrieve translations for current locale',
      )
    }

    // eslint-disable-next-line no-param-reassign
    nuxt.payload.state[NUXT_STATE_KEY] = {
      locale,
      translations,
    }
  }

  if (!isTestEnv() && !nuxt.payload.state[NUXT_STATE_KEY]) {
    throw new Error(
      '[nuxt-module-i18n] Cannot retrieve translations in useState',
    )
  }
})
