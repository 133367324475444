import {
  buybackCustomerAPI,
  buybackSellerAPI,
  buybackStaffAPI,
} from '@backmarket/http-api'

export const ROLES = {
  CUSTOMER: 'CUSTOMER',
  STAFF: 'STAFF',
  MERCHANT: 'MERCHANT',
  AUTO_MESSAGE: 'AUTO_MESSAGE',
} as const

export type Role = keyof typeof ROLES

export const getConversationsEndoints = (role: Role) => {
  switch (role) {
    case ROLES.STAFF:
    case ROLES.AUTO_MESSAGE:
      return {
        getConversation: buybackStaffAPI.getStaffOrderMessages,
        getMetadata: buybackStaffAPI.getStaffOrderMetadata,
        postMessage: buybackStaffAPI.postStaffOrderMessage,
      }
    case ROLES.MERCHANT:
      return {
        getConversation: buybackSellerAPI.getSellerOrderMessages,
        getMetadata: buybackSellerAPI.getSellerOrderMetadata,
        postMessage: buybackSellerAPI.postSellerOrderMessage,
      }
    case ROLES.CUSTOMER:
    default:
      return {
        getConversation: buybackCustomerAPI.getCustomerOrderMessages,
        getMetadata: buybackCustomerAPI.getCustomerOrderMetadata,
        postMessage: buybackCustomerAPI.postCustomerOrderMessage,
      }
  }
}
