<template>
  <RevContainer class="py-6">
    <h1 class="heading-1 my-32">ActiveFilters</h1>

    <section class="my-24">
      <ActiveFilters
        v-model:filters="filters"
        v-model:price="priceInput"
        :facets="facets"
        :max-price="0"
        :search-nb-results="1"
      />
    </section>

    <section class="my-24">
      <ActiveFilters
        v-model:filters="filters"
        v-model:price="priceInput"
        :facets="facets"
        :max-price="0"
        :search-nb-results="2"
      />
    </section>

    <section class="my-24">
      <ActiveFilters
        v-model:price="priceInput"
        :facets="facets"
        :filters="{}"
        :max-price="0"
        :search-nb-results="9999999"
      />
    </section>
  </RevContainer>
</template>

<script lang="ts" setup>
import { ref, shallowReactive } from 'vue'

import { RevContainer } from '@ds/components/Container'

import { useNoIndexMeta } from '~/composables/useNoIndexMeta'
import ActiveFilters from '~/scopes/product-list/components/ActiveFilters.vue'

useNoIndexMeta()

const facets = [
  {
    name: 'payment_methods',
    title: 'Méthodes de paiement',
    type: 'checkbox' as const,
    values: [
      {
        label: 'iDEAL',
        name: '-1 iDEAL',
        count: 1,
        position: 0,
      },
      {
        label: ' Carte de crédit',
        name: '0  Carte de crédit',
        count: 1,
        position: 1,
      },
    ],
  },
  {
    name: 'shipping_free',
    title: 'Livraison offerte',
    type: 'radio' as const,
    values: [
      {
        label: 'Oui',
        name: 'Oui',
        count: 1,
        position: 0,
      },
    ],
  },
]
const filters = shallowReactive<Record<string, string[]>>({
  shipping_free: [],
  payment_methods: ['0  Carte de crédit'],
})
const priceInput = ref<[number, number]>([0, 0])
</script>
