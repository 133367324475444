import type { RouteRecordRaw } from 'vue-router'

export const AdminRoutes = {
  HOME: 'admin',
}

const routes: readonly RouteRecordRaw[] = [
  {
    name: AdminRoutes.HOME,
    path: '/bo_merchant/admin_tools/dashboard',
    component: () =>
      import(
        '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
      ),
    meta: { disableClientSideNavigation: () => true },
  },
]

export default routes
