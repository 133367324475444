import { useNuxtApp } from '#imports'
import { type VNode } from 'vue'

import type { Currency, Locale } from '@backmarket/http-api'
import { isTestEnv } from '@backmarket/utils/env/isTestEnv'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'
import { IntlMessageFormat } from 'intl-messageformat'

import { NUXT_STATE_KEY, PROCESS_KEY } from '../../constants'
import type { I18nDefinition, I18nValues } from '../../types'

interface Arguments {
  locale: Locale
  showTranslationKeys?: boolean
  showDefaultMessage?: boolean
  currencyCode: Currency
  shouldLogMissingTranslationValue?: boolean
}

export default function createMessageFormatter({
  locale,
  showTranslationKeys = false,
  showDefaultMessage = false,
  currencyCode,
  shouldLogMissingTranslationValue = true,
}: Arguments) {
  return <Message extends string>(
    definition: I18nDefinition<Message>,
    values?: I18nValues<Message>,
  ) => {
    /**
     * In some cases, when definitions are computed from dynamic values,
     * we may end up in situations where nothing is passed to this function.
     */
    if (!definition) {
      // eslint-disable-next-line no-console
      console.error('[nuxt-module-i18n] Missing translation definition', {
        locale,
      })

      /**
       * Instead of crashing the application, let's gracefully handle the error
       * by displaying nothing to our end customers.
       */
      return null
    }

    try {
      /**
       * Do not display those warning in unit tests
       * as we don't have translations there.
       */
      const translation = process.server
        ? process[PROCESS_KEY]?.translationsByLocale[locale]?.[definition.id]
        : useNuxtApp().payload.state[NUXT_STATE_KEY].translations?.[
            definition.id
          ]

      if (!showDefaultMessage && !translation && !isTestEnv()) {
        if (translation === undefined) {
          // eslint-disable-next-line no-console
          console.error('[nuxt-module-i18n] Missing translation key', {
            definition,
            locale,
          })
        } else if (translation === '' && shouldLogMissingTranslationValue) {
          // eslint-disable-next-line no-console
          console.error('[nuxt-module-i18n] Missing translation value', {
            definition,
            locale,
          })
        }
      }

      if (showTranslationKeys) {
        return definition.id
      }

      const raw =
        !showDefaultMessage && translation
          ? translation
          : definition.defaultMessage

      const escaped = raw.replace(/{(\w*), html}/g, "'{$1}'")

      const message = new IntlMessageFormat(escaped, toBcp47Locale(locale), {
        number: {
          currency: {
            style: 'currency',
            currency: currencyCode,
          },
        },
      })

      /**
       * Specifying VNode was necessary: message.format accepts a record object where values are of type Primitive | T | FormatXmlElement
       * T is inferred as string but it should be inferred as VNode. If T is inferred as string, we get a type error as VNode does not match Primitive | string | FormatXmlElement
       * */
      return message.format<VNode>(values) as string
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[nuxt-module-i18n] - createMessageFormatter', { error })

      // If the key does not have a translation nor a default message.
      return null
    }
  }
}
