import { defineNuxtRouteMiddleware } from '#imports'

import { useAuthStore } from '@backmarket/nuxt-module-oauth/useAuthStore'
import { useUserStore } from '@backmarket/nuxt-module-oauth/useUserStore'

// https://nuxt.com/docs/guide/directory-structure/middleware
export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.client) return

  const authStore = useAuthStore()
  const userStore = useUserStore()

  if (authStore.fetched === false) {
    await userStore.fetchUser()
  }
})
