import type { RouteRecordRaw } from 'vue-router'

export const CUSTOMER_REQUESTS = {
  ASP_RESOLUTION_CONVERSATION: 'asp_resolution_conversation',
}

const routes: readonly RouteRecordRaw[] = [
  {
    path: 'dashboard/customer-request/resolutions/:resolutionId',
    name: CUSTOMER_REQUESTS.ASP_RESOLUTION_CONVERSATION,
    component: () =>
      import(
        '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
      ),
    meta: {
      auth: {
        required: true,
      },
      disableClientSideNavigation: () => true,
    },
  },
]

export default routes
