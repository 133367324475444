import type { RouteRecordRaw } from 'vue-router'

import { insertIf } from '@backmarket/utils/collection/insertIf'

import type { Features } from '../../features'

import { CMS } from './routes-names'

const outsideAppRoutes: RouteRecordRaw[] = [
  {
    name: 'bo-seller.register',
    path: 'bo-seller/register',
    component: () =>
      import(
        '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
      ),
    meta: {
      disableClientSideNavigation: () => true,
    },
  },
  {
    name: 'bo-seller.login',
    path: 'bo-seller/login',
    component: () =>
      import(
        '@backmarket/nuxt-layer-base/components/ExternalPagePlaceholder/ExternalPagePlaceholder.vue'
      ),
    meta: {
      disableClientSideNavigation: () => true,
    },
  },
]

const buybackRoutes: RouteRecordRaw[] = [
  {
    name: CMS.BUYBACK,
    path: 'buyback/:pageName',
    component: () => import('./Landing.vue'),
  },
  {
    name: CMS.BUYBACK_CATEGORY,
    path: 'buyback/:pageName/:pageCategory',
    component: () => import('./Landing.vue'),
  },
]

export default ({ features }: { features: Features }): RouteRecordRaw[] => {
  const isBuybackEnabled = features.buyback?.enabled

  return [
    {
      name: CMS.ARTICLE_ROOT_HUB,
      path: 'c',
      component: () => import('~/scopes/article/Hub/pages/ArticlesHub.vue'),
    },
    {
      name: CMS.ARTICLE_HUB,
      // note the backend always return 'hub_buying_guide'. We need to use that for category hub and fix the breadcrumb logic for the root
      path: 'c/hub/:unifiedName',
      component: () => import('~/scopes/article/Hub/pages/ArticlesHub.vue'),
    },
    {
      name: CMS.ARTICLE,
      path: 'c/:category/:pageName',
      component: () => import('~/scopes/article/Article/Article.vue'),
    },

    {
      name: CMS.BACK_STORIES,
      path: 'back-stories/:pageName',
      component: () => import('./Landing.vue'),
    },
    {
      name: CMS.BACK_STORIES_CATEGORY,
      path: 'back-stories/:pageName/:pageCategory',
      component: () => import('./Landing.vue'),
    },

    {
      name: CMS.BRAND_STORE,
      path: 'b/:pageName',
      component: () => import('./Landing.vue'),
    },
    {
      name: CMS.BRAND_STORE_CATEGORY,
      path: 'b/:pageName/:pageCategory',
      component: () => import('./Landing.vue'),
    },

    // conditional CMS.BUYBACK routes
    ...insertIf(isBuybackEnabled, buybackRoutes),

    {
      name: CMS.EVENT,
      path: 'e/:pageName',
      component: () => import('./Landing.vue'),
    },

    {
      name: CMS.GIFT_FINDER_MAIN,
      path: 'gift/:pageName',
      component: () => import('./Landing.vue'),
    },
    {
      name: CMS.GIFT_FINDER_EVENT,
      path: 'gift/:pageName/:pageCategory',
      component: () => import('./Landing.vue'),
    },

    {
      name: CMS.REFER_FRIEND,
      path: 'refer-friend',
      component: () => import('./Landing.vue'),
    },

    {
      name: CMS.SELLER,
      path: 'seller/:pageName',
      component: () => import('./Landing.vue'),
    },

    {
      name: CMS.SERVICE,
      path: '/:locale/service/:pageName',
      component: () => import('./Landing.vue'),
    },

    {
      name: CMS.STATEMENT,
      path: 'accessibility-statement',
      component: () => import('./Landing.vue'),
    },

    {
      name: CMS.E2E_PAGE,
      path: 'e2e/:pageName',
      component: () => import('./Landing.vue'),
      meta: {
        disableClientSideNavigation: () => true,
      },
    },
    ...outsideAppRoutes,
  ]
}
