import type { ContentLink } from '@backmarket/http-api/src/api-specs-content/models/content-link'

const envRegExp = /local|preprod|dse-.*\.dse|www/

export function transformHref({
  href,
  currentUrl,
}: {
  href: string
  currentUrl: URL
}): ContentLink {
  try {
    const url = new URL(href)

    const isUrlEnvSubdomain = url.hostname.match(envRegExp)
    const currentHostname = currentUrl.hostname.replace(envRegExp, '')

    if (url.hostname.match(currentHostname) && isUrlEnvSubdomain) {
      return { href: url.pathname + url.search + url.hash }
    }

    return { href, target: '_blank', rel: 'nofollow' }
  } catch (error) {
    return { href }
  }
}
