import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import { HOME } from '../home/route-names'

import { ROUTES } from './routes.constants'

export default (): RouteRecordRaw[] => {
  const config = useRuntimeConfig()

  return [
    {
      path: '/:locale/zendesk-pre-trade-in',
      name: ROUTES.ZENDESK.PRE_TRADE_IN,
      ...(config.public.FF_ENABLE_CARE_CHAT_POCS
        ? {
            component: () =>
              import('./components/ZendeskPreTradeIn/ZendeskPreTradeIn.vue'),
          }
        : { redirect: { name: HOME } }),
      meta: {
        disableClientSideNavigation: () => false,
      },
    },
    {
      path: '/:locale/zendesk-resolution-options',
      name: ROUTES.ZENDESK.RESOLUTION_OPTIONS,
      ...(config.public.FF_ENABLE_CARE_CHAT_POCS
        ? {
            component: () =>
              import('./components/ZendeskPostSales/ResolutionOptionsPage.vue'),
          }
        : { redirect: { name: HOME } }),
      meta: {
        disableClientSideNavigation: () => false,
      },
    },
    {
      path: '/:locale/zendesk-customer-live-chat',
      name: ROUTES.ZENDESK.CUSTOMER_LIVE_CHAT,
      ...(config.public.FF_ENABLE_CARE_CHAT_POCS
        ? {
            component: () =>
              import('./components/ZendeskPostSales/CustomerLiveChatPage.vue'),
          }
        : { redirect: { name: HOME } }),
      meta: {
        disableClientSideNavigation: () => false,
      },
    },
  ]
}
