import { navigateTo, useRuntimeConfig } from '#imports'
import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

import { PAGE_NAME_BY_PLP_SLUG } from '../cms/service/content-service.config'

import { ROUTES as PRODUCT_LIST_ROUTES } from './route-names'

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const { FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_PLP } =
    useRuntimeConfig().public

  const disableClientSideNavigation = !(
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_PLP as string
  )?.includes(market.countryCode)

  const availableUniverseSlugs = PAGE_NAME_BY_PLP_SLUG[market.countryCode]
  const universeRegex = availableUniverseSlugs
    ? Object.keys(availableUniverseSlugs).join('|')
    : '!?'

  return [
    {
      path: 'search',
      name: PRODUCT_LIST_ROUTES.SEARCH_RESULTS,
      meta: { disableClientSideNavigation: () => disableClientSideNavigation },
      component: () => import('./pages/SearchResultPage.vue'),
    },
    {
      path: 's/:merchantName/:uuid',
      name: PRODUCT_LIST_ROUTES.SELLER_PRODUCT_LIST,
      meta: { disableClientSideNavigation: () => disableClientSideNavigation },
      component: () => import('./pages/SellerProductListPage.vue'),
    },
    {
      path: 'l/:slugV2/:uuid',
      name: PRODUCT_LIST_ROUTES.PRODUCT_LIST,
      meta: {
        disableClientSideNavigation: () => disableClientSideNavigation,
        middleware: (to: RouteLocationNormalized) => {
          if (
            Array.isArray(to.params.slugV2) || !availableUniverseSlugs
              ? false
              : to.params.slugV2 in availableUniverseSlugs
          ) {
            const universePage: RouteLocationNormalized = {
              ...to,
              name: 'universe_page',
            }

            return navigateTo(universePage)
          }

          return true
        },
      },
      component: () => import('./pages/ProductListPage.vue'),
    },
    {
      name: PRODUCT_LIST_ROUTES.UNIVERSE,
      component: () => import('../cms/Landing.vue'),
      path: `l/:slugV2(${universeRegex})/:uuid`,
    },
  ]
}
