import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import RouterLinkComponent from '@backmarket/nuxt-module-navigation/RouterLinkComponent.vue'
import { addTrailingSlash } from '@backmarket/utils/url/addTrailingSlash'
import { removeLeadingSlash } from '@backmarket/utils/url/removeLeadingSlash'
import { createRevolvePlugin } from '@ds/plugin'

export default defineNuxtPlugin((nuxtApp) => {
  const { app: appConfig } = useRuntimeConfig()
  const { currentLocale } = useI18n()

  const illustrationOptimizer = (path: string) => {
    return appConfig.cdnURL
      ? new URL(removeLeadingSlash(path), addTrailingSlash(appConfig.cdnURL))
          .href
      : path
  }

  const dsVuePlugin = createRevolvePlugin({
    RouterLinkComponent,
    illustrationOptimizer,
    locale: ref(currentLocale as string),
  })

  nuxtApp.vueApp.use(dsVuePlugin)
})
