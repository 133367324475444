import { default as action_45sticky_45bar23aX8ymFKIMeta } from "/var/www/apps/front-office/pages/[locale]/sr-demopage/action-sticky-bar.vue?macro=true";
import { default as active_45filtersqzAihpXKVVMeta } from "/var/www/apps/front-office/pages/[locale]/sr-demopage/active-filters.vue?macro=true";
import { default as indexCV1teRXLqtMeta } from "/var/www/apps/front-office/pages/[locale]/sr-demopage/index.vue?macro=true";
import { default as navigationAUaOelDcNmMeta } from "/var/www/apps/front-office/pages/[locale]/sr-demopage/navigation.vue?macro=true";
import { default as paginationjwCPidKXgQMeta } from "/var/www/apps/front-office/pages/[locale]/sr-demopage/pagination.vue?macro=true";
import { default as product_45cardsd4fiuQlQmuMeta } from "/var/www/apps/front-office/pages/[locale]/sr-demopage/product-cards.vue?macro=true";
import { default as refinements_46mockQshKeP16mwMeta } from "/var/www/apps/front-office/pages/[locale]/sr-demopage/refinements.mock.ts?macro=true";
import { default as refinementsfdfTlBF5ZMMeta } from "/var/www/apps/front-office/pages/[locale]/sr-demopage/refinements.vue?macro=true";
import { default as trust_45listwTTY9e2ZylMeta } from "/var/www/apps/front-office/pages/[locale]/sr-demopage/trust-list.vue?macro=true";
export default [
  {
    name: action_45sticky_45bar23aX8ymFKIMeta?.name ?? "locale-sr-demopage-action-sticky-bar",
    path: action_45sticky_45bar23aX8ymFKIMeta?.path ?? "/:locale()/sr-demopage/action-sticky-bar",
    meta: action_45sticky_45bar23aX8ymFKIMeta || {},
    alias: action_45sticky_45bar23aX8ymFKIMeta?.alias || [],
    redirect: action_45sticky_45bar23aX8ymFKIMeta?.redirect,
    component: () => import("/var/www/apps/front-office/pages/[locale]/sr-demopage/action-sticky-bar.vue").then(m => m.default || m)
  },
  {
    name: active_45filtersqzAihpXKVVMeta?.name ?? "locale-sr-demopage-active-filters",
    path: active_45filtersqzAihpXKVVMeta?.path ?? "/:locale()/sr-demopage/active-filters",
    meta: active_45filtersqzAihpXKVVMeta || {},
    alias: active_45filtersqzAihpXKVVMeta?.alias || [],
    redirect: active_45filtersqzAihpXKVVMeta?.redirect,
    component: () => import("/var/www/apps/front-office/pages/[locale]/sr-demopage/active-filters.vue").then(m => m.default || m)
  },
  {
    name: indexCV1teRXLqtMeta?.name ?? "locale-sr-demopage",
    path: indexCV1teRXLqtMeta?.path ?? "/:locale()/sr-demopage",
    meta: indexCV1teRXLqtMeta || {},
    alias: indexCV1teRXLqtMeta?.alias || [],
    redirect: indexCV1teRXLqtMeta?.redirect,
    component: () => import("/var/www/apps/front-office/pages/[locale]/sr-demopage/index.vue").then(m => m.default || m)
  },
  {
    name: navigationAUaOelDcNmMeta?.name ?? "locale-sr-demopage-navigation",
    path: navigationAUaOelDcNmMeta?.path ?? "/:locale()/sr-demopage/navigation",
    meta: navigationAUaOelDcNmMeta || {},
    alias: navigationAUaOelDcNmMeta?.alias || [],
    redirect: navigationAUaOelDcNmMeta?.redirect,
    component: () => import("/var/www/apps/front-office/pages/[locale]/sr-demopage/navigation.vue").then(m => m.default || m)
  },
  {
    name: paginationjwCPidKXgQMeta?.name ?? "locale-sr-demopage-pagination",
    path: paginationjwCPidKXgQMeta?.path ?? "/:locale()/sr-demopage/pagination",
    meta: paginationjwCPidKXgQMeta || {},
    alias: paginationjwCPidKXgQMeta?.alias || [],
    redirect: paginationjwCPidKXgQMeta?.redirect,
    component: () => import("/var/www/apps/front-office/pages/[locale]/sr-demopage/pagination.vue").then(m => m.default || m)
  },
  {
    name: product_45cardsd4fiuQlQmuMeta?.name ?? "locale-sr-demopage-product-cards",
    path: product_45cardsd4fiuQlQmuMeta?.path ?? "/:locale()/sr-demopage/product-cards",
    meta: product_45cardsd4fiuQlQmuMeta || {},
    alias: product_45cardsd4fiuQlQmuMeta?.alias || [],
    redirect: product_45cardsd4fiuQlQmuMeta?.redirect,
    component: () => import("/var/www/apps/front-office/pages/[locale]/sr-demopage/product-cards.vue").then(m => m.default || m)
  },
  {
    name: refinements_46mockQshKeP16mwMeta?.name ?? "locale-sr-demopage-refinements.mock",
    path: refinements_46mockQshKeP16mwMeta?.path ?? "/:locale()/sr-demopage/refinements.mock",
    meta: refinements_46mockQshKeP16mwMeta || {},
    alias: refinements_46mockQshKeP16mwMeta?.alias || [],
    redirect: refinements_46mockQshKeP16mwMeta?.redirect,
    component: () => import("/var/www/apps/front-office/pages/[locale]/sr-demopage/refinements.mock.ts").then(m => m.default || m)
  },
  {
    name: refinementsfdfTlBF5ZMMeta?.name ?? "locale-sr-demopage-refinements",
    path: refinementsfdfTlBF5ZMMeta?.path ?? "/:locale()/sr-demopage/refinements",
    meta: refinementsfdfTlBF5ZMMeta || {},
    alias: refinementsfdfTlBF5ZMMeta?.alias || [],
    redirect: refinementsfdfTlBF5ZMMeta?.redirect,
    component: () => import("/var/www/apps/front-office/pages/[locale]/sr-demopage/refinements.vue").then(m => m.default || m)
  },
  {
    name: trust_45listwTTY9e2ZylMeta?.name ?? "locale-sr-demopage-trust-list",
    path: trust_45listwTTY9e2ZylMeta?.path ?? "/:locale()/sr-demopage/trust-list",
    meta: trust_45listwTTY9e2ZylMeta || {},
    alias: trust_45listwTTY9e2ZylMeta?.alias || [],
    redirect: trust_45listwTTY9e2ZylMeta?.redirect,
    component: () => import("/var/www/apps/front-office/pages/[locale]/sr-demopage/trust-list.vue").then(m => m.default || m)
  }
]